import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import authAccount from '@/middleware/authAccount';
import guestOnly from '@/middleware/guestOnly';

import Main from '../views/index.vue'
import Success from '../views/success.vue'
import Password from '../views/password.vue'
import Auth from '../views/auth.vue'
import Login from '../views/login.vue'
import Statistic from '../views/account/statistic.vue'
import Community from '../views/account/community.vue'
import Rating from '../views/account/rating.vue'
import Profile from '../views/profile.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    beforeEnter: guestOnly,
    meta: {
      title: 'Главная'
    }
  },
  {
    path: '/success',
    component: Success,
    meta: {
      title: 'Регистрация успешна'
    }
  },
  {
    path: '/auth',
    component: Auth,
    meta: {
      title: 'Авторизация'
    }
  },
  {
    path: '/password',
    component: Password,
    beforeEnter: authAccount,
    meta: {
      title: 'Как сменить пароль?'
    }
  },
  {
    path: '/login',
    component: Login,
    beforeEnter: guestOnly,
    meta: {
      title: 'Авторизация'
    }
  },
  {
    path: '/account/statistic',
    component: Statistic,
    beforeEnter: authAccount,
    meta: {
      title: 'Статистика клуба'
    }
  },
  {
    path: '/account/rating',
    component: Rating,
    beforeEnter: authAccount,
    meta: {
      title: 'Рейтинги'
    }
  },
  {
    path: '/account/community',
    component: Community,
    beforeEnter: authAccount,
    meta: {
      title: 'Тренировки участников'
    }
  },
  {
    path: '/profile',
    component: Profile,
    beforeEnter: authAccount,
    meta: {
      title: 'Личный кабинет'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token')
  if (token) {
    try {
      await store.dispatch('auth/getUser')
    } catch (error) {
      localStorage.removeItem('token')
      next('/login')
      return
    }
  }
  next()
})


export default router
